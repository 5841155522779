<template>
  <div class="row mt-6">
    <div class="col-3">
      <!-- Google -->
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Google Kalendar
          </h5>
          <p class="card-text">
            Verbinde deinen Googlen Kalender mit Bookingflix.
          </p>
          <div v-if="isDisabled">
            <p style="color: #e0d441">Zuerst den Bookingflix-Kalendar wählen</p>
          </div>
          <button v-else ref="authorizeButtonGoogle" @click="handleClickAction" type="button" class="flix-btn flix-btn-default">
            Einloggen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly'

export default {
  name: 'googleCalendarIntegration',
  mounted () {
    this.$nextTick(function () {
      this.addScript()
      // msgraph.handleClientLoad(this.$refs.authorizeButtonMS)
    })
    this.fetchConnectedCalendars()
  },
  props: {
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedCalendarId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoggedIn: false
  }),
  watch: {
    selectedCalendarId (newValue) {
      this.fetchConnectedCalendars()
    }
  },
  methods: {
    addScript () {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/api.js'
      script.async = true
      document.head.appendChild(script)
    },
    async startAuthorization () {
      // eslint-disable-next-line no-undef
      await window.gapi.load('client:auth2', this.initClient)
    },
    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    async initClient () {
      // eslint-disable-next-line no-undef
      const apiKey = process.env.VUE_APP_GOOGLE_API_KEY
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
      const redirectUri = process.env.VUE_APP_URL

      await window.gapi.client.init({
        apiKey: apiKey,
        clientId: clientId,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        redirectUri: redirectUri
      })

      this.handleAuthClick()
    },
    handleClickAction () {
      if (this.isLoggedIn) {
        this.handleSignoutClick()
      } else {
        this.startAuthorization()
      }
    },
    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    updateSigninStatus (isSignedIn, calendarName = '') {
      if (isSignedIn) {
        this.isDisabled = false
        this.$refs.authorizeButtonGoogle.setAttribute('class', 'flix-btn flix-btn-success')
        this.$refs.authorizeButtonGoogle.innerHTML = 'Verknüpfung mit ' + calendarName + ' löschen'
      } else {
        this.$refs.authorizeButtonGoogle.setAttribute('class', 'flix-btn flix-btn-default')
        this.$refs.authorizeButtonGoogle.innerHTML = 'Einloggen'
      }
    },

    /**
     *  Sign in the user upon button click.
     */
    handleAuthClick (event) {
      // eslint-disable-next-line no-undef
      window.gapi.auth2.getAuthInstance().signIn()
      // eslint-disable-next-line no-undef
      const authInstance = window.gapi.auth2.getAuthInstance()
      authInstance.grantOfflineAccess()
        .then((res) => {
          window.gapi.auth2.getAuthInstance().currentUser.listen((currentUser) => {
            const curUser = window.gapi.auth2.getAuthInstance().currentUser.get()
            const authData = curUser.getAuthResponse()
            const profile = curUser.getBasicProfile()

            this.$flix_post({
              url: 'external_calendar/google/create_connection',
              data: {
                user: this.$store.getters.user.md5_id,
                authCode: res.code,
                calendarId: this.selectedCalendarId,
                name: profile.getEmail(),
                accessToken: authData.access_token,
                expires_in: authData.expires_in
              },
              callback: function (ret) {
                this.updateSigninStatus(true, profile.getEmail())
                this.isLoggedIn = true
              }.bind(this)
            })
          })
        })
    },

    /**
     *  Sign out the user upon button click.
     */
    handleSignoutClick (event) {
      this.$flix_post({
        url: 'external_calendar/google/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          calendarId: this.selectedCalendarId
        },
        callback: function (ret) {
          this.updateSigninStatus(false)
          this.isLoggedIn = false
        }.bind(this)
      })
    },

    fetchConnectedCalendars () {
      this.$flix_post({
        url: 'external_calendar/google/get',
        data: {
          user: this.$store.getters.user.md5_id,
          calendarId: this.selectedCalendarId
        },
        callback: function (ret) {
          const calendarName = ret.data[1].calendar

          if (calendarName && calendarName.length > 1) {
            this.updateSigninStatus(true, calendarName)
            this.isLoggedIn = true
          }
        }.bind(this)
      })
    }
  }
}
</script>

<style scoped>

</style>
